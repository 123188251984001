import React, { useEffect } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import get from "lodash/get";
import styled from "@emotion/styled";
import stageOneData from "../../../hooks/payments-data-one";
import { theme } from "../../../utils/global-styles/theme";
import PrimaryButton from "../../../components/shared/PrimaryButton/PrimaryButton";
import { _post } from "../../../utils/services/api.service";

const PaymentCardEntry = ({
  elements,
  stripe,
  customerPaymentId,
  userDetails,
  selectedCourse,
  promoName,
  setPromoName,
  setLoading,
  setPaymentTotalDetails,
  setInvoiceId,
  setCardDetails,
  setCustomerId,
  setPromoCodeError,
  paymentTotalDetails,
  setPaymentsStage,
  setHasFreeCoursePromo,
  setIntentId,
  setCustomerPaymentInformationReceived,
}) => {
  const stageOneVerbiage = stageOneData(),
    SectionTitle = styled.h5`
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;
      letter-spacing: -0.22px;
      :nth-of-type(2) {
        margin-top: 40px;
        @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
          font-size: 20px;
          font-weight: 500;
          letter-spacing: -0.18px;
          margin-top: 20px;
        }
      }
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.18px;
        margin-top: 20px;
      }
    `,
    PaymentForm = styled.form``;

  const handlePaymentInformationSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `https://localhost:8000/payment?customer=${customerPaymentId}`,
      },
      redirect: "if_required",
    });

    setLoading(true);
    if (error) {
      console.log("error===========>", error);
    } else {
      setCustomerPaymentInformationReceived(true);
      if (
        paymentTotalDetails.discount !== "" &&
        paymentTotalDetails.tax !== ""
      ) {
        const quoteRequestParams = {
          user: {
            email: userDetails.email || "",
            provider_id: userDetails.provider_id,
            external_id: userDetails.external_id,
          },
          promo_code: promoName || "",
          course: {
            course_id: selectedCourse.courseId,
            course_name: selectedCourse.title,
            provider_url: selectedCourse.providerUrl,
            provider: selectedCourse.provider,
          },
          source_token: "",
          customer_id: customerPaymentId,
          currency: selectedCourse.currency,
          flexible_amount: selectedCourse?.flexible_amount,
          amount: Number(selectedCourse?.courseCost?.slice(1)),
        };

        const quoteResponse = await _post({
          endpoint: "payments",
          args: quoteRequestParams,
        });

        if (quoteResponse.success) {
          setPromoName(promoName);
          setLoading(false);
          const quote = get(quoteResponse, "data.payment.quote");
          paymentTotalDetails.tax = `${selectedCourse.currencySymbol}${
            quote.find(q => q.type === "tax").amount
          }`;
          paymentTotalDetails.total = `${selectedCourse.currencySymbol}${
            quote.find(q => q.type === "total").amount
          }`;
          paymentTotalDetails.subTotal = `${selectedCourse.currencySymbol}${
            quote.find(q => q.type === "price").amount
          }`;
          setPaymentTotalDetails(paymentTotalDetails);
          setInvoiceId(get(quoteResponse, "data.payment.invoice_id", null));
          setCardDetails(get(quoteResponse, "data.payment.card", null));
          setCustomerId(get(quoteResponse, "data.customer_id", ""));
          setPaymentsStage(quoteResponse.data.stage);
          setIntentId(get(quoteResponse, "data.payment.intent", null));
        } else {
          setPromoCodeError(
            `There was a problem: ${quoteResponse.errors[0].message}`
          );
        }
      } else {
        setPaymentsStage(2);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className="CheckoutForm">
        <PaymentForm onSubmit={e => handlePaymentInformationSubmit(e)}>
          <SectionTitle>
            {stageOneVerbiage.contentModules[0].title}
          </SectionTitle>
          <PaymentElement
            id="payment-element"
            onReady={() => setLoading(false)}
          />
          <button className="black-button" type="submit">
            Submit
          </button>
        </PaymentForm>
      </div>
    </>
  );
};

export default PaymentCardEntry;
