import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme } from "../../../utils/global-styles/theme";
import PromoInput from "./PromoInput";
import { _post } from "../../../utils/services/api.service";

const PriceDetails = ({
  courseType,
  title,
  courseAvailability,
  tax,
  paymentToken,
  stage,
  updateTotal,
  verbiagePieces,
  courseTotal,
  subTotal,
  promo,
  promoName,
  hasFreeCoursePromo,
  setHasFreeCoursePromo,
  paymentsStage,
  setPaymentsStage,
  setPaymentTotalDetails,
  selectedCourse,
  promoCodeError,
  setPromoName,
}) => {
  const LightSeaGreenBox = styled.div`
      background-color: ${theme.colors.lightSeaGreen};
      padding: 20px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        margin-top: 40px;
      }
    `,
    CourseType = styled.p`
      font-family: ${theme.fontFaces.headers};
      font-size: 17px;
      font-weight: bold;
    `,
    CourseTitle = styled.h4`
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.22px;
      line-height: 24px;
      margin: 8px 0;
    `,
    CourseAvailability = styled.p`
      font-family: ${theme.fontFaces.headers};
      font-size: 16px;
      letter-spacing: -0.25px;
      font-weight: lighter;
    `,
    Divider = styled.hr`
      background-color: ${theme.colors.light};
      margin: 12px -20px;
      display: block;
    `,
    SubtotalText = styled.p`
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.25px;
      font-family: ${theme.fontFaces.headers};
      display: flex;
      justify-content: space-between;
      margin: 12px 0;
    `,
    TaxCalculationText = styled.p`
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.37px;
      font-family: ${theme.fontFaces.headers};
      font-weight: 400;
    `,
    PaymentText = styled.h4`
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.22px;
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
    `,
    PromoDisplayWrapper = styled.div`
      display: flex;
      flex-direction: column;
      p {
        font-family: ${theme.fontFaces.headers};
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        :nth-of-type(2) {
          justify-content: flex-start;
          font-size: 13px;
          span {
            :nth-of-type(1) {
              margin-right: 16px;
            }
            :nth-of-type(2) {
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    `,
    PromoInputWrapper = styled.div`
      background: ${theme.colors.white};
      display: flex;
      justify-content: space-between;
      padding: 8px;
      border-width: ${props => (props.promoCodeError ? "2px" : "")};
      border-style: ${props => (props.promoCodeError ? "solid" : "")};
      &:focus-within {
        border-width: 2px;
        border-style: solid;
      }
    `,
    PromoCodeError = styled.p`
      color: ${theme.colors.red};
      margin-top: 16px;
      display: block;

      > .error {
        color: ${theme.colors.red};
      }
    `,
    Wrapper = styled.div`
      @media only screen and (min-width: ${theme.breakpoints.desktopUp}) {
        width: 400px;
      }
    `;
  return (
    <Wrapper>
      <LightSeaGreenBox>
        <CourseType>{courseType}</CourseType>
        <CourseTitle className="primary">{title}</CourseTitle>
        <Divider />
        <SubtotalText>
          {verbiagePieces.options[0]}{" "}
          <span>
            {selectedCourse.flexiblePayment ||
            ("flexible_amount" in selectedCourse &&
              selectedCourse.flexible_amount)
              ? `${selectedCourse.currencySymbol}${selectedCourse.flexible_amount}`
              : `${selectedCourse.currencySymbol}${Number(
                  subTotal.slice(1)
                ).toFixed(2)}`}
          </span>
        </SubtotalText>
        {courseTotal !== "Free" && (
          <>
            <SubtotalText>
              {verbiagePieces.options[1]}{" "}
              <span>
                {tax
                  ? `${selectedCourse.currencySymbol}${Number(
                      tax.slice(1)
                    ).toFixed(2)}`
                  : `${selectedCourse.currencySymbol}0.00`}
              </span>
            </SubtotalText>
            {stage === 1 && !selectedCourse.flexible_amount && (
              <TaxCalculationText>
                {verbiagePieces.description.description}
              </TaxCalculationText>
            )}
          </>
        )}
        <Divider />
        <PaymentText>
          {verbiagePieces.options[2]}{" "}
          {stage === 1 && (
            <span>
              {selectedCourse.flexiblePayment &&
              "flexible_amount" in selectedCourse &&
              selectedCourse.flexible_amount
                ? `${selectedCourse.currencySymbol}${selectedCourse.flexible_amount}`
                : `${selectedCourse.currencySymbol}${Number(
                    courseTotal.slice(1)
                  ).toFixed(2)}`}
            </span>
          )}
          {stage === 2 && <span>{courseTotal}</span>}
        </PaymentText>
      </LightSeaGreenBox>
      <PromoCodeError>{promoCodeError}</PromoCodeError>
    </Wrapper>
  );
};

PriceDetails.propTypes = {
  courseType: PropTypes.string,
  title: PropTypes.string,
  courseAvailability: PropTypes.string,
  tax: PropTypes.string,
  paymentToken: PropTypes.string,
  stage: PropTypes.number,
  updateTotal: PropTypes.func,
  verbiagePieces: PropTypes.shape({
    link: PropTypes.shape({
      linkText: PropTypes.string,
    }),
    description: PropTypes.shape({
      description: PropTypes.string,
    }),
    options: PropTypes.arrayOf(PropTypes.string),
  }),
  courseTotal: PropTypes.string,
  subTotal: PropTypes.string,
  promo: PropTypes.string,
  promoName: PropTypes.string,
  hasFreeCoursePromo: PropTypes.bool,
  setPaymentsStage: PropTypes.func,
  setPromoName: PropTypes.func,
  setPaymentAddress: PropTypes.func,
};

export default PriceDetails;
