import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import { navigate } from "gatsby";
import styled from "@emotion/styled";
import { theme } from "../../../utils/global-styles/theme";
import stageTwoData from "../../../hooks/payments-data-two";
import PrimaryButton from "../../../components/shared/PrimaryButton/PrimaryButton";

const PaymentVerify = ({
  cardDetails,
  paymentAddress,
  processPayment,
  setPaymentsStage,
  slug,
  footerHeight,
  formErrors,
  hasFreeCoursePromo,
  courseType,
  callPaymentIntent,
}) => {
  const stageTwoVerbiage = stageTwoData(),
    SectionTitle = styled.h5`
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.22px;
      margin: 50px 0 20px 0;
    `,
    DetailsText = styled.p`
      font-size: 14px;
      font-weight: 400;
      font-family: ${theme.fontFaces.headers};
      line-height: 24px;
    `,
    EditLink = styled.p`
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.34px;
      border-bottom-style: solid;
      border-bottom-width: 2px;
      font-family: ${theme.fontFaces.headers};
      display: inline-block;
      &:hover {
        cursor: pointer;
        font-weight: 800;
      }
    `,
    ButtonContainer = styled.div`
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        bottom: calc(${footerHeight}px + 70px);
      }
    `,
    FreeCourseVerbiage = styled.p`
      font-family: ${theme.fontFaces.headers};
      font-size: 17px;
      font-weight: 500;
      letter-spacing: -0.37px;
      line-height: 20px;
    `;
  return (
    <>
      {hasFreeCoursePromo ? (
        <>
          <SectionTitle
            css={css`
              margin-top: 0px;
            `}
          >
            {stageTwoVerbiage.contentModules[0].title}
          </SectionTitle>
          <FreeCourseVerbiage className="primary">
            Payment and billing information not required with this promo code!
          </FreeCourseVerbiage>
        </>
      ) : (
        <>
          <div>
            <SectionTitle>
              {stageTwoVerbiage.contentModules[0].title}
            </SectionTitle>
            <DetailsText>{`${cardDetails.name || ""}`}</DetailsText>
            <DetailsText>{`${cardDetails.brand} ending in ${cardDetails.last4}`}</DetailsText>
            <DetailsText>{`Expires ${cardDetails.exp_month}/${cardDetails.exp_year}`}</DetailsText>
            <EditLink
              onClick={() => {
                callPaymentIntent();
                setPaymentsStage(1);
              }}
              className="primary primary-underline secondary-hover"
            >
              {stageTwoVerbiage.contentModules[0].link.linkText}
            </EditLink>
          </div>
        </>
      )}
      {formErrors || null}
      <ButtonContainer>
      <button
          onClick={processPayment}
          css={css`
            background-color: transparent;
            width: 37.5%;
            padding: 10px 20px;
            @media only screen and (max-width: ${theme.breakpoints
                .helper1100}) {
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 0.34px;
            }
          `}
          className={
            courseType === ("Master Class" || "On-Demand")
              ? "mrkt-last-form-pmtbody black-button"
              : "black-button"
          }
          type="submit"
        >
          {stageTwoVerbiage.contentModules[3].linkText}
        </button>
        <button
          onClick={e => {
            e.preventDefault();
            const pendingCourseEnrollmentDetails = localStorage.getItem(
              "pendingCourseEnrollmentDetails"
            );
            if (pendingCourseEnrollmentDetails) {
              localStorage.removeItem("pendingCourseEnrollmentDetails");
            }
            navigate(`/course/${slug}/`);
          }}
          css={css`
            background-color: transparent;
            width: 37.5%;
            padding: 10px 20px;
            @media only screen and (max-width: ${theme.breakpoints
                .helper1100}) {
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 0.34px;
            }
          `}
          className="black-button"
          type="submit"
        >
          {stageTwoVerbiage.contentModules[4].linkText}
        </button>
      </ButtonContainer>
    </>
  );
};

PaymentVerify.propTypes = {
  cardDetails: PropTypes.shape({
    name: PropTypes.string,
    brand: PropTypes.string,
    last4: PropTypes.string,
    exp_month: PropTypes.number,
  }),
  paymentAddress: PropTypes.shape({
    line1: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postal_code: PropTypes.string,
  }),
  processPayment: PropTypes.func,
  setPaymentsStage: PropTypes.func,
  slug: PropTypes.string,
  footerHeight: PropTypes.number,
  formErrors: PropTypes.node,
  courseType: PropTypes.string,
};

export default connect(
  state => ({
    footerHeight: state.fellowsReducer.footerHeight,
  }),
  null
)(PaymentVerify);
