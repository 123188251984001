import { graphql, useStaticQuery } from "gatsby";

const paymentsStageThreeData = () => {
  const data = useStaticQuery(graphql`
    query {
      stageThree: allContentfulPageLayout(
        filter: {
          queryIdentifier: { eq: "payment-three" }
          node_locale: { eq: "en-US" }
        }
      ) {
        nodes {
          heading
          shortDescription
          additionalText
          contentModules {
            ... on ContentfulLink {
              linkText
              url
            }
          }
        }
      }
    }
  `);
  return data.stageThree.nodes[0];
};

export default paymentsStageThreeData;
