import { graphql, useStaticQuery } from "gatsby";

const paymentsStageTwoData = () => {
  const data = useStaticQuery(graphql`
    query {
      stageTwo: allContentfulPageLayout(
        filter: {
          queryIdentifier: { eq: "payment-two" }
          node_locale: { eq: "en-US" }
        }
      ) {
        nodes {
          heading
          shortDescription
          contentModules {
            ... on ContentfulTitleDescription {
              title
              link {
                linkText
              }
              description {
                description
              }
              options
            }
            ... on ContentfulLink {
              linkText
            }
          }
        }
      }
    }
  `);
  return data.stageTwo.nodes[0];
};

export default paymentsStageTwoData;
